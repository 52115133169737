import React from "react";
import SunflowerLoader from "../../assets/sunfloweropac.png";
import "./AppLoader.css";

const AppLoader = ({ isLoading }) => {
  if (!isLoading) return null;

  return (
    <div className="app-loader-container">
      <img src={SunflowerLoader} alt="Loading..." className="app-loader" />
    </div>
  );
};

export default AppLoader; 