import React, { useEffect, useRef, useState } from "react";
import "./VerificationModal.css";

const VerificationModal = ({
  isOpen,
  onClose,
  onVerify,
  phone,
  resendCode,
}) => {
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [timer, setTimer] = useState(60);
  const [canResend, setCanResend] = useState(false);
  const inputRef = useRef(null);

  // Фокус на инпуте при открытии модального окна
  useEffect(() => {
    if (isOpen && inputRef.current) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 100);
    }
  }, [isOpen]);

  // Таймер для повторной отправки кода
  useEffect(() => {
    let interval;

    if (isOpen && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setCanResend(true);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isOpen, timer]);

  // Сброс состояний при закрытии/открытии модального окна
  useEffect(() => {
    if (isOpen) {
      setCode("");
      setError("");
      setTimer(60);
      setCanResend(false);
    }
  }, [isOpen]);

  const handleCodeChange = (e) => {
    const inputValue = e.target.value.replace(/\D/g, "");

    // Ограничиваем ввод 6 цифрами
    if (inputValue.length <= 6) {
      setCode(inputValue);
      setError("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!code) {
      setError("Введите код верификации");
      return;
    }

    if (code.length !== 3) {
      setError("Код должен состоять из 3 цифр");
      return;
    }

    setIsLoading(true);

    try {
      await onVerify(code);
    } catch (err) {
      setError(err.message || "Ошибка верификации");
    } finally {
      setIsLoading(false);
    }
  };

  const handleResend = async () => {
    setIsLoading(true);

    try {
      await resendCode();
      setCanResend(false);
      setTimer(60);
    } catch (err) {
      setError(err.message || "Ошибка при повторной отправке кода");
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="verification-modal-overlay">
      <div className="verification-modal">
        <div className="verification-modal-header">
          <h2>Подтверждение номера телефона</h2>
          <button className="close-button" onClick={onClose}>
            &times;
          </button>
        </div>

        <div className="verification-modal-body">
          <p>
            Мы отправили код подтверждения на ваш WhatsApp:
            <br />
            <strong>{phone}</strong>
          </p>

          <form onSubmit={handleSubmit}>
            <div className="verification-input-container">
              <input
                ref={inputRef}
                type="text"
                value={code}
                onChange={handleCodeChange}
                placeholder="Введите 3-значный код"
                maxLength={3}
                disabled={isLoading}
                inputMode="numeric"
                pattern="[0-9]*"
              />
            </div>

            {error && <div className="verification-error">{error}</div>}

            <div className="verification-buttons">
              <button
                type="submit"
                className="verify-button"
                disabled={isLoading || code.length !== 3}
              >
                {isLoading ? "Проверка..." : "Подтвердить"}
              </button>

              {timer > 0 ? (
                <div className="resend-timer">
                  Повторная отправка через {timer} сек
                </div>
              ) : (
                <button
                  type="button"
                  className="resend-button"
                  onClick={handleResend}
                  disabled={isLoading || !canResend}
                >
                  Отправить код повторно
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default VerificationModal;
