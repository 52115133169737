import React, { useEffect, useState } from "react";
import { getNotes } from "./api/api";
import { sendVerificationCode, verifyCode } from "./api/verification";
import "./App.css";
import AppLoader from "./components/AppLoader/AppLoader";
import OrderForm from "./components/OrderForm";
import VerificationModal from "./components/VerificationModal";


function App() {
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [successMessage, setSuccessMessage] = useState("");
  const [error, setError] = useState(null);
  // Состояние для верификации
  const [isVerificationModalOpen, setIsVerificationModalOpen] = useState(false);
  const [verificationPhone, setVerificationPhone] = useState("");
  const [verificationFormData, setVerificationFormData] = useState(null);

  // Автоматическое скрытие сообщений через 2 секунды
  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage("");
      }, 4000);

      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  // То же самое для сообщений об ошибке
  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError(null);
      }, 4000);

      return () => clearTimeout(timer);
    }
  }, [error]);

  const fetchNotes = async () => {
    try {
      const data = await getNotes();
      setNotes(Array.isArray(data) ? data : []);
      setLoading(false);
      setError(null);
    } catch (error) {
      console.error("Error details:", error);
      setError(error.message);
      setLoading(false);
      setNotes([]);
    }
  };

  useEffect(() => {
    fetchNotes();
  }, []);

  const handleOrderSubmit = async (formData) => {
    try {
      console.log("Отправка заказа на верификацию:", formData);

      // Сохраняем данные формы для использования после верификации
      setVerificationFormData(formData);
      setVerificationPhone(formData.phone);

      // Отправляем код верификации
      await sendVerificationCode(formData);

      // Открываем модальное окно верификации
      setIsVerificationModalOpen(true);
    } catch (error) {
      console.log("Ошибка при отправке кода верификации:", error);
      console.log("Детали ошибки:", {
        message: error.message,
        status: error.status,
        data: error.data,
      });

      setError(
        "Не удалось отправить код верификации. Пожалуйста, проверьте номер телефона и попробуйте снова."
      );
    }
  };

  const handleVerifyCode = async (code) => {
    try {
      // Проверяем код и подтверждаем заказ
      const response = await verifyCode(verificationPhone, code);

      // Если верификация успешна, закрываем модальное окно
      setIsVerificationModalOpen(false);

      // Проверяем наличие driverInfo перед использованием
      if (response.driverInfo) {
        const { name, phone } = response.driverInfo;
        setSuccessMessage(
          `Заявка принята! Ваш водитель: ${name}, телефон: ${phone}`
        );
      } else {
        // Если driverInfo отсутствует, показываем сообщение без информации о водителе
        setSuccessMessage("Заявка принята! Водитель будет назначен позже.");
      }

      fetchNotes();
    } catch (error) {
      console.log("Ошибка при верификации кода:", error);
      throw new Error(
        error.data?.message ||
          "Ошибка верификации. Проверьте код и повторите попытку."
      );
    }
  };

  const handleResendCode = async () => {
    try {
      // Повторно отправляем код верификации
      await sendVerificationCode(verificationFormData);
      return true;
    } catch (error) {
      console.log("Ошибка при повторной отправке кода:", error);
      throw new Error(
        "Не удалось отправить код повторно. Пожалуйста, попробуйте снова позже."
      );
    }
  };

  if (loading) {
    return <AppLoader isLoading={loading} />;
  }

  return (
    <div className="App">
      {error && <div className="error-message">{error}</div>}
      {successMessage && (
        <div className="success-message">{successMessage}</div>
      )}
      <OrderForm notes={notes} onSubmit={handleOrderSubmit} />

      <VerificationModal
        isOpen={isVerificationModalOpen}
        onClose={() => setIsVerificationModalOpen(false)}
        onVerify={handleVerifyCode}
        phone={verificationPhone}
        resendCode={handleResendCode}
      />
    </div>
  );
}

export default App;
