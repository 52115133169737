import axios from "axios";

// Проверяем, как настроен baseURL
const baseURL = process.env.REACT_APP_API_URL || "http://localhost:3001";

// Создаем экземпляр axios с базовым URL
const api = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

// Добавляем интерцепторы для обработки ошибок
api.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error("API Error:", error);
    return Promise.reject(error);
  }
);

// Добавляем функцию для условного логирования
const conditionalLog = (...args) => {
  if (process.env.NODE_ENV !== "production") {
    console.log(...args);
  }
};

// Обновляем интерцептор запросов
api.interceptors.request.use((request) => {
  conditionalLog("Full URL:", request.baseURL + request.url);
  conditionalLog("Request data:", request.data);
  conditionalLog("Request headers:", request.headers);
  return request;
});

// Убираем безусловный console.log
conditionalLog("API URL:", baseURL);

// Экспортируем объект api
export { api };

export const getNotes = async () => {
  try {
    console.log("API URL:", baseURL);
    console.log("Full URL:", `${baseURL}/api/notes`);
    console.log("Request headers:", api.defaults.headers);

    const response = await api.get("/api/notes");
    return response.data;
  } catch (error) {
    console.error("Error fetching notes:", error);
    const errorObj = new Error(error.message);
    errorObj.status = error.response?.status;
    errorObj.data = error.response?.data;
    throw errorObj;
  }
};

export const updateOrder = async (id, phone) => {
  try {
    console.log("Full URL:", `${baseURL}/api/notes/${id}`);
    console.log("Request data:", { phone, order: true });
    console.log("Request headers:", api.defaults.headers);

    const response = await api.put(`/api/notes/${id}`, { phone, order: true });
    return response.data;
  } catch (error) {
    console.error("Error updating order:", error);

    // Проверяем, есть ли информация о существующей заметке с этим номером
    if (
      error.response &&
      error.response.status === 400 &&
      error.response.data.existingNote
    ) {
      const duplicateError = new Error("Номер телефона уже используется");
      duplicateError.existingNote = error.response.data.existingNote;
      duplicateError.isPhoneDuplicate = true;
      throw duplicateError;
    }

    throw error;
  }
};

// Добавляем функцию для создания нового кафе
export const createNewCafe = async (cafeData) => {
  try {
    console.log("Full URL:", `${baseURL}/api/notes`);
    console.log("Request data:", cafeData);
    console.log("Request headers:", api.defaults.headers);

    const response = await api.post(`/api/notes`, cafeData);
    return response.data;
  } catch (error) {
    console.error("Error creating new cafe:", error);
    const errorObj = new Error(error.message);
    errorObj.status = error.response?.status;
    errorObj.data = error.response?.data;
    throw errorObj;
  }
};
