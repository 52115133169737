import React, { useCallback, useEffect, useRef, useState } from "react";
import "react-phone-input-2/lib/style.css";
import Select from "react-select";
import "./OrderForm.css";

const OrderForm = ({ notes, onSubmit }) => {
  // Проверяем, что notes является массивом и не пустой
  const validNotes = Array.isArray(notes) ? notes : [];

  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedCafe, setSelectedCafe] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [phone, setPhone] = useState("7");
  const [phoneComment, setPhoneComment] = useState("");
  const [submittedPhones, setSubmittedPhones] = useState(new Set());
  const [phoneError, setPhoneError] = useState("");
  const [phoneInfo, setPhoneInfo] = useState("");
  const [phoneFocused, setPhoneFocused] = useState(false);

  // Состояния для заявки на фритюрное масло
  const [fryoilOrderActive, setFryoilOrderActive] = useState(false);
  const [fryoilOrderValue, setFryoilOrderValue] = useState("");
  // Состояние для отображения дополнительной информации о фритюрном масле
  const [showFryoilInfo, setShowFryoilInfo] = useState(false);

  // Состояние для заявки на жироуловитель
  const [greaseOrderActive, setGreaseOrderActive] = useState(false);
  // Состояние для отображения дополнительной информации о жироуловителе
  const [showGreaseInfo, setShowGreaseInfo] = useState(false);

  // Состояния для модального окна просмотра изображения
  const [showImageModal, setShowImageModal] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  const [currentImageAlt, setCurrentImageAlt] = useState("");

  // Добавляем состояния для ручного ввода
  const [isCustomCity, setIsCustomCity] = useState(false);
  const [isCustomCafe, setIsCustomCafe] = useState(false);
  const [isCustomAddress, setIsCustomAddress] = useState(false);
  const [customCity, setCustomCity] = useState("");
  const [customCafe, setCustomCafe] = useState("");
  const [customAddress, setCustomAddress] = useState("");
  const [customCityError, setCustomCityError] = useState("");
  const [customCafeError, setCustomCafeError] = useState("");
  const [customAddressError, setCustomAddressError] = useState("");

  // Состояния для отслеживания фокуса в полях ввода
  const [cityFocused, setCityFocused] = useState(false);
  const [cafeFocused, setCafeFocused] = useState(false);
  const [addressFocused, setAddressFocused] = useState(false);
  const [commentFocused, setCommentFocused] = useState(false);
  const [fryoilFocused, setFryoilFocused] = useState(false);

  // Состояния для отслеживания фокуса в селектах
  const [citySelectFocused, setCitySelectFocused] = useState(false);
  const [cafeSelectFocused, setCafeSelectFocused] = useState(false);
  const [addressSelectFocused, setAddressSelectFocused] = useState(false);

  // Создаем ссылки на элементы Select
  const citySelectRef = useRef(null);
  const cafeSelectRef = useRef(null);
  const addressSelectRef = useRef(null);

  // Модифицируем функцию disableAutofill, чтобы она также предотвращала появление клавиатуры
  const disableAutofill = useCallback((selectRef) => {
    try {
      if (selectRef?.current?.select?.inputRef) {
        const inputElement = selectRef.current.select.inputRef;
        inputElement.setAttribute("autocomplete", "off");
        inputElement.setAttribute("autocorrect", "off");
        inputElement.setAttribute("spellcheck", "false");
        inputElement.setAttribute("autocapitalize", "off");
        inputElement.setAttribute("role", "presentation");
        inputElement.setAttribute("aria-autocomplete", "none");
        inputElement.setAttribute("data-lpignore", "true");

        // Добавляем атрибут readonly для предотвращения появления клавиатуры
        inputElement.setAttribute("readonly", "readonly");
        inputElement.setAttribute("inputmode", "none");
        inputElement.setAttribute("aria-hidden", "true");
        inputElement.setAttribute("tabindex", "-1");

        // Добавляем обработчики событий для предотвращения появления клавиатуры
        inputElement.addEventListener("focus", preventKeyboard);
        inputElement.addEventListener("touchstart", preventKeyboard, {
          passive: false,
        });
        inputElement.addEventListener("click", preventKeyboard);
        inputElement.addEventListener("mousedown", preventKeyboard);

        // Добавляем CSS-класс для предотвращения появления клавиатуры
        inputElement.classList.add("no-keyboard");

        // Активно блокируем фокус
        inputElement.blur();
      }
    } catch (error) {
      console.log("Селект еще не инициализирован");
    }
  }, []);

  // Отключаем автозаполнение при монтировании компонента
  useEffect(() => {
    // Отключаем автозаполнение для всех полей ввода
    const timer = setTimeout(() => {
      disableAutofill(citySelectRef);
      disableAutofill(cafeSelectRef);
      disableAutofill(addressSelectRef);

      // Отключаем автозаполнение для всех input полей
      const inputs = document.querySelectorAll("input");
      inputs.forEach((input) => {
        input.setAttribute("autocomplete", "off");
        input.setAttribute("autocorrect", "off");
        input.setAttribute("spellcheck", "false");
        input.setAttribute("autocapitalize", "off");
        input.setAttribute("role", "presentation");
        input.setAttribute("aria-autocomplete", "none");
        input.setAttribute("data-lpignore", "true");
      });

      // Добавляем атрибуты к форме
      const form = document.querySelector(".order-form");
      if (form) {
        form.setAttribute("autocomplete", "off");
        form.setAttribute("autocorrect", "off");
        form.setAttribute("spellcheck", "false");
        form.setAttribute("autocapitalize", "off");
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [disableAutofill]);

  const resetForm = () => {
    setSelectedCity(null);
    setSelectedCafe(null);
    setSelectedAddress(null);
    setPhone("7");
    setPhoneComment("");
    setPhoneError("");
    setPhoneInfo("");
    setIsCustomCity(false);
    setIsCustomCafe(false);
    setIsCustomAddress(false);
    setCustomCity("");
    setCustomCafe("");
    setCustomAddress("");
    setCustomCityError("");
    setCustomCafeError("");
    setCustomAddressError("");
    // Сбрасываем состояния для заявок
    setFryoilOrderActive(false);
    setFryoilOrderValue("");
    setGreaseOrderActive(false);
    // Сбрасываем состояния фокуса
    setPhoneFocused(false);
    setCityFocused(false);
    setCafeFocused(false);
    setAddressFocused(false);
    setCommentFocused(false);
    setFryoilFocused(false);
    setCitySelectFocused(false);
    setCafeSelectFocused(false);
    setAddressSelectFocused(false);
  };

  // Функция для ручного форматирования номера телефона
  const formatPhoneNumber = (value) => {
    if (!value) return "";

    // Удаляем все нецифровые символы
    const digitsOnly = value.replace(/\D/g, "");

    // Не форматируем, если цифр меньше 1
    if (digitsOnly.length < 1) return "";

    // Форматируем как (XXX) XXX-XX-XX
    if (digitsOnly.length <= 3) {
      return `(${digitsOnly}`;
    } else if (digitsOnly.length <= 6) {
      return `(${digitsOnly.substring(0, 3)}) ${digitsOnly.substring(3)}`;
    } else if (digitsOnly.length <= 8) {
      return `(${digitsOnly.substring(0, 3)}) ${digitsOnly.substring(
        3,
        6
      )}-${digitsOnly.substring(6)}`;
    } else {
      return `(${digitsOnly.substring(0, 3)}) ${digitsOnly.substring(
        3,
        6
      )}-${digitsOnly.substring(6, 8)}-${digitsOnly.substring(8, 10)}`;
    }
  };

  // Обновленный обработчик изменения номера телефона
  const handleCustomPhoneChange = (e) => {
    // Получаем только цифры из ввода
    let value = e.target.value.replace(/\D/g, "");

    // Если первый символ 7 или 8, удаляем его, так как у нас уже есть префикс +7
    if (value.length > 0 && (value[0] === "7" || value[0] === "8")) {
      value = value.substring(1);
    }

    // Проверяем, что первая цифра 9 для мобильных номеров России
    if (value.length > 0 && value[0] !== "9") {
      setPhoneError("Номер должен начинаться с 9 (формат: +7 9XX XXX-XX-XX)");
      return;
    } else {
      setPhoneError("");
    }

    // Ограничиваем длину до 10 цифр (без кода страны)
    const trimmedValue = value.substring(0, 10);

    // Добавляем код страны 7 перед номером
    setPhone(trimmedValue ? `7${trimmedValue}` : "7");
    setPhoneInfo("");
  };

  // Функция для преобразования первой буквы каждого слова в заглавную
  const capitalizeFirstLetters = (text) => {
    if (!text) return "";
    return text
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  // Получаем уникальные города и добавляем опцию "Другой город"
  const cityOptions = [...new Set(validNotes.map((note) => note.city))]
    .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
    .map((city) => ({ value: city, label: city }));

  // Добавляем опцию "Другой город" в конец списка
  cityOptions.push({ value: "custom", label: "Другой город..." });

  // Получаем кафе для выбранного города
  const cafeOptions =
    selectedCity && selectedCity.value !== "custom"
      ? [
          ...new Set(
            validNotes
              .filter((note) => note.city === selectedCity.value)
              .map((note) => note.description)
          ),
        ]
          .filter((cafe) => cafe)
          .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
          .map((cafe) => ({ value: cafe, label: cafe }))
      : [];

  // Добавляем опцию "Другое кафе" в конец списка кафе
  if (
    selectedCity &&
    selectedCity.value !== "custom" &&
    cafeOptions.length > 0
  ) {
    cafeOptions.push({ value: "custom", label: "Другое кафе..." });
  }

  // Получаем адреса для выбранного кафе
  const addressOptions =
    selectedCafe && selectedCafe.value !== "custom"
      ? validNotes
          .filter(
            (note) =>
              note.city === selectedCity.value &&
              note.description === selectedCafe.value
          )
          .map((note) => ({
            value: note._id,
            label: `${note.street} ${note.house}`.trim(),
          }))
          .sort((a, b) =>
            a.label.toLowerCase().localeCompare(b.label.toLowerCase())
          )
      : [];

  // Добавляем опцию "Другой адрес" в конец списка адресов
  if (
    selectedCafe &&
    selectedCafe.value !== "custom" &&
    addressOptions.length > 0
  ) {
    addressOptions.push({ value: "custom", label: "Другой адрес..." });
  }

  const handleCityChange = (option) => {
    setSelectedCity(option);
    setSelectedCafe(null);
    setSelectedAddress(null);
    setPhoneError("");
    setPhoneInfo("");

    // Проверяем, выбрал ли пользователь "Другой город"
    if (option && option.value === "custom") {
      setIsCustomCity(true);
      setIsCustomCafe(true);
      setIsCustomAddress(true);
      setCustomCity("");
      setCustomCafe("");
      setCustomAddress("");
    } else {
      setIsCustomCity(false);
      setIsCustomCafe(false);
      setIsCustomAddress(false);
      setCustomCityError("");
      setCustomCafeError("");
      setCustomAddressError("");
    }

    // Сбрасываем режим работы при смене города
    setPhoneComment("");
  };

  const handleCafeChange = (option) => {
    setSelectedCafe(option);
    setSelectedAddress(null);
    setPhoneError("");
    setPhoneInfo("");

    // Проверяем, выбрал ли пользователь "Другое кафе"
    if (option && option.value === "custom") {
      setIsCustomCafe(true);
      setIsCustomAddress(true);
      setCustomCafe("");
      setCustomAddress("");
    } else {
      setIsCustomCafe(false);
      setCustomCafeError("");
      setIsCustomAddress(false);
      setCustomAddress("");
      setCustomAddressError("");
    }

    // Сбрасываем режим работы при смене кафе
    setPhoneComment("");
  };

  const handleAddressChange = (option) => {
    setSelectedAddress(option);
    setPhoneError("");
    setPhoneInfo("");

    // Проверяем, выбрал ли пользователь "Другой адрес"
    if (option && option.value === "custom") {
      setIsCustomAddress(true);
      setCustomAddress("");
    } else {
      setIsCustomAddress(false);
      setCustomAddressError("");

      // Получаем режим работы из выбранной записи, если это не "Другой адрес"
      if (option && option.value !== "custom") {
        const selectedNote = notes.find((note) => note._id === option.value);
        if (selectedNote && selectedNote.worktime) {
          // Заполняем поле режима работы из базы данных
          setPhoneComment(selectedNote.worktime);
          console.log(`Загружен режим работы: ${selectedNote.worktime}`);
        }
      }
    }
  };

  // Добавляем обработчик для комментария к телефону
  const handlePhoneCommentChange = (e) => {
    setPhoneComment(e.target.value);
  };

  // Обработчики для полей ручного ввода
  const handleCustomCityChange = (e) => {
    setCustomCity(e.target.value);
    setCustomCityError("");
  };

  const handleCustomCafeChange = (e) => {
    setCustomCafe(e.target.value);
    setCustomCafeError("");
  };

  const handleCustomAddressChange = (e) => {
    setCustomAddress(e.target.value);
    setCustomAddressError("");
  };

  // Обработчики фокуса для полей ввода
  const handlePhoneFocus = () => {
    setPhoneFocused(true);
  };

  const handlePhoneBlur = () => {
    if (phone === "7") {
      setPhoneFocused(false);
    }
  };

  const handleCityFocus = () => {
    setCityFocused(true);
  };

  const handleCityBlur = () => {
    if (!customCity) {
      setCityFocused(false);
    }
  };

  const handleCafeFocus = () => {
    setCafeFocused(true);
  };

  const handleCafeBlur = () => {
    if (!customCafe) {
      setCafeFocused(false);
    }
  };

  const handleAddressFocus = () => {
    setAddressFocused(true);
  };

  const handleAddressBlur = () => {
    if (!customAddress) {
      setAddressFocused(false);
    }
  };

  const handleCommentFocus = () => {
    setCommentFocused(true);
  };

  const handleCommentBlur = () => {
    if (!phoneComment) {
      setCommentFocused(false);
    }
  };

  const handleFryoilFocus = () => {
    setFryoilFocused(true);
  };

  const handleFryoilBlur = () => {
    if (!fryoilOrderValue) {
      setFryoilFocused(false);
    }
  };

  const validateCustomFields = () => {
    let isValid = true;

    if (isCustomCity && !customCity.trim()) {
      setCustomCityError("Введите название города");
      isValid = false;
    }

    if (isCustomCafe && !customCafe.trim()) {
      setCustomCafeError("Введите название кафе");
      isValid = false;
    }

    if (isCustomAddress && !customAddress.trim()) {
      setCustomAddressError("Введите адрес");
      isValid = false;
    }

    return isValid;
  };

  // Обработчики для полей заявок
  const handleFryoilToggle = () => {
    setFryoilOrderActive(!fryoilOrderActive);
    if (fryoilOrderActive) {
      setFryoilOrderValue(""); // Сбрасываем значение при отключении
    }
  };

  const handleFryoilValueChange = (e) => {
    // Разрешаем только числа
    const value = e.target.value.replace(/[^0-9]/g, "");
    setFryoilOrderValue(value);
  };

  const handleGreaseToggle = () => {
    setGreaseOrderActive(!greaseOrderActive);
  };

  // Функции для переключения отображения дополнительной информации
  const toggleFryoilInfo = () => {
    setShowFryoilInfo(!showFryoilInfo);
  };

  const toggleGreaseInfo = () => {
    setShowGreaseInfo(!showGreaseInfo);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Проверяем длину номера телефона
    if (phone.replace(/\D/g, "").length < 11) {
      setPhoneError("Введите корректный номер телефона");
      setPhoneInfo("");
      return;
    }

    // Проверяем поля ручного ввода, если они используются
    if (!validateCustomFields()) {
      return;
    }

    // Проверяем, что выбран адрес, если не используется ручной ввод адреса
    if (!isCustomAddress && !selectedAddress) {
      setPhoneError("Выберите адрес");
      return;
    }

    // Проверяем, введено ли значение для фритюрного масла, если активно
    if (fryoilOrderActive && !fryoilOrderValue) {
      setPhoneError("Введите количество фритюрного масла");
      return;
    }

    // Нормализуем номер телефона для проверки
    const normalizedPhone = formatPhoneNumber(phone);

    // Проверяем, был ли уже отправлен этот номер телефона и показываем информационное сообщение
    // но не блокируем отправку формы
    if (submittedPhones.has(normalizedPhone)) {
      console.log(
        `Номер ${phone} уже был использован ранее, но разрешаем повторное использование`
      );
      // Информируем пользователя, но не блокируем отправку
      setPhoneError("");
      setPhoneInfo("Этот номер уже использовался ранее");
      // Небольшая задержка перед отправкой, чтобы пользователь увидел сообщение
      setTimeout(() => {
        submitOrder(normalizedPhone);
      }, 1000);
      return;
    }

    submitOrder(normalizedPhone);
  };

  // Функция для отправки заказа
  const submitOrder = (normalizedPhone) => {
    let orderDetails;

    // Определяем тип заказа и формируем данные
    if (isCustomCity) {
      // Полностью новое кафе в новом городе
      orderDetails = {
        phone,
        worktime: phoneComment,
        city: capitalizeFirstLetters(customCity.trim()),
        cafe: capitalizeFirstLetters(customCafe.trim()),
        address: capitalizeFirstLetters(customAddress.trim()),
        isNewCafe: true,
        isNewCity: true,
        isNewAddress: true,
        // Добавляем данные о заявках
        fryoilOrder: {
          active: fryoilOrderActive,
          value: fryoilOrderActive ? parseInt(fryoilOrderValue) || 0 : null,
        },
        greaseOrder: {
          active: greaseOrderActive,
        },
      };
    } else if (isCustomCafe) {
      // Новое кафе в существующем городе
      orderDetails = {
        phone,
        worktime: phoneComment,
        city: selectedCity.label,
        cafe: capitalizeFirstLetters(customCafe.trim()),
        address: capitalizeFirstLetters(customAddress.trim()),
        isNewCafe: true,
        isNewCity: false,
        isNewAddress: true,
        // Добавляем данные о заявках
        fryoilOrder: {
          active: fryoilOrderActive,
          value: fryoilOrderActive ? parseInt(fryoilOrderValue) || 0 : null,
        },
        greaseOrder: {
          active: greaseOrderActive,
        },
      };
    } else if (isCustomAddress) {
      // Новый адрес для существующего кафе
      orderDetails = {
        phone,
        worktime: phoneComment,
        city: selectedCity.label,
        cafe: selectedCafe.label,
        address: capitalizeFirstLetters(customAddress.trim()),
        isNewCafe: false,
        isNewCity: false,
        isNewAddress: true,
        // Добавляем данные о заявках
        fryoilOrder: {
          active: fryoilOrderActive,
          value: fryoilOrderActive ? parseInt(fryoilOrderValue) || 0 : null,
        },
        greaseOrder: {
          active: greaseOrderActive,
        },
      };
    } else {
      // Существующий адрес
      orderDetails = {
        phone,
        worktime: phoneComment,
        city: selectedCity.label,
        cafe: selectedCafe.label,
        address: selectedAddress.label,
        id: selectedAddress.value,
        isNewCafe: false,
        isNewCity: false,
        isNewAddress: false,
        // Добавляем данные о заявках
        fryoilOrder: {
          active: fryoilOrderActive,
          value: fryoilOrderActive ? parseInt(fryoilOrderValue) || 0 : null,
        },
        greaseOrder: {
          active: greaseOrderActive,
        },
      };
    }

    // Добавляем номер в список отправленных
    setSubmittedPhones((prev) => new Set([...prev, normalizedPhone]));

    onSubmit(orderDetails);
    resetForm();
  };

  // Получаем элемент портала для меню
  const getMenuPortalTarget = () => {
    // Используем document.body вместо формы для более надежного позиционирования
    return document.body;
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: "45px",
      marginBottom: "10px",
      backgroundColor: "white",
      border: "1px solid #ddd",
      borderRadius: "4px",
      boxShadow: "none",
      "&:hover": {
        borderColor: "#b3b3b3",
      },
    }),
    menu: (base) => ({
      ...base,
      position: "absolute",
      zIndex: 9999,
      width: "100%",
      left: 0,
      right: "auto",
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999,
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected
        ? "#2684ff"
        : state.isFocused
        ? "#f0f0f0"
        : "white",
      color: state.isSelected ? "white" : "#333",
      padding: "10px 12px",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: state.isSelected ? "#2684ff" : "#f0f0f0",
      },
    }),
    container: (base) => ({
      ...base,
      position: "relative",
    }),
    input: (base) => ({
      ...base,
      backgroundColor: "transparent",
      margin: "0",
      padding: "0",
      "input::-webkit-calendar-picker-indicator": {
        display: "none !important",
      },
    }),
    valueContainer: (base) => ({
      ...base,
      backgroundColor: "transparent",
      padding: "2px 8px 2px 8px",
    }),
    singleValue: (base) => ({
      ...base,
      backgroundColor: "transparent",
      color: "#333",
      marginLeft: "0",
      marginRight: "0",
      position: "relative",
      transform: "none",
      top: "0",
    }),
    placeholder: (base) => ({
      ...base,
      color: "transparent",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: "6px",
      color: "#999",
      "&:hover": {
        color: "#666",
      },
    }),
    clearIndicator: (base) => ({
      ...base,
      padding: "6px",
      color: "#999",
      "&:hover": {
        color: "#666",
      },
    }),
    indicatorSeparator: (base) => ({
      ...base,
      backgroundColor: "#ddd",
    }),
    menuList: (base) => ({
      ...base,
      padding: "0",
      maxHeight: "250px",
    }),
  };

  // Добавляем эффект для таймера камеры
  useEffect(() => {
    const minutesLabel = document.getElementById("minutes");
    const secondsLabel = document.getElementById("seconds");
    let totalSeconds = 0;

    const timer = setInterval(() => {
      ++totalSeconds;
      if (secondsLabel && minutesLabel) {
        secondsLabel.innerHTML = pad(totalSeconds % 60);
        minutesLabel.innerHTML = pad(parseInt(totalSeconds / 60));
      }
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const pad = (val) => {
    const valString = val + "";
    return valString.length < 2 ? "0" + valString : valString;
  };

  // Улучшаем функцию preventKeyboard
  const preventKeyboard = (e) => {
    // Проверяем, не относится ли элемент к полю ввода количества фритюрного масла
    if (e.target.classList.contains("quantity-input")) {
      return; // Не блокируем клавиатуру для этого поля
    }

    // Предотвращаем появление клавиатуры на мобильных устройствах
    e.target.setAttribute("readonly", "readonly");
    e.target.setAttribute("inputmode", "none");
    e.target.setAttribute("aria-hidden", "true");

    // Предотвращаем стандартное поведение для touchstart и click
    if (
      e.type === "touchstart" ||
      e.type === "click" ||
      e.type === "mousedown"
    ) {
      e.preventDefault();
      e.stopPropagation();
    }

    // Активно блокируем фокус
    e.target.blur();

    // Удаляем атрибут readonly после открытия селекта
    setTimeout(() => {
      e.target.removeAttribute("readonly");
    }, 100);
  };

  // В начале файла, после импортов, добавляем стили для предотвращения появления клавиатуры
  useEffect(() => {
    // Добавляем стили для предотвращения появления клавиатуры
    const style = document.createElement("style");
    style.textContent = `
      .no-keyboard {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        pointer-events: none;
        caret-color: transparent;
      }
      
      .react-select__input input {
        opacity: 0 !important;
        position: absolute !important;
        top: -9999px !important;
        left: -9999px !important;
        height: 1px !important;
        width: 1px !important;
        z-index: -1 !important;
        overflow: hidden !important;
        pointer-events: none !important;
        caret-color: transparent !important;
        -webkit-text-fill-color: transparent !important;
      }
      
      .react-select__control {
        pointer-events: auto !important;
      }
      
      /* Дополнительные стили для предотвращения появления клавиатуры */
      .react-select__value-container {
        cursor: pointer !important;
      }
      
      /* Скрываем курсор в поле ввода */
      .react-select__input {
        caret-color: transparent !important;
      }
    `;
    document.head.appendChild(style);

    // Глобальный обработчик для предотвращения появления клавиатуры
    const handleGlobalTouchStart = (e) => {
      if (e.target.closest(".react-select__control")) {
        const input = e.target.closest(".react-select").querySelector("input");
        if (input) {
          input.setAttribute("readonly", "readonly");
          input.setAttribute("inputmode", "none");
          input.setAttribute("aria-hidden", "true");
          input.blur();
        }
      }
    };

    document.addEventListener("touchstart", handleGlobalTouchStart, {
      passive: false,
    });

    return () => {
      document.head.removeChild(style);
      document.removeEventListener("touchstart", handleGlobalTouchStart);
    };
  }, []);

  // Добавляем эффект для предотвращения проблем с виртуальной клавиатурой
  useEffect(() => {
    // Функция для скролла к активному элементу
    const handleFocusInput = (e) => {
      // Проверяем, чтобы это был input или textarea
      if (e.target.tagName === "INPUT" || e.target.tagName === "TEXTAREA") {
        // Подождем немного, чтобы клавиатура появилась
        setTimeout(() => {
          // Проверим, видим ли элемент
          const rect = e.target.getBoundingClientRect();
          const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight;

          // Если элемент не полностью видим, прокрутим к нему
          if (!isVisible) {
            e.target.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }, 300);
      }
    };

    // Добавляем обработчик для всех полей ввода
    document.addEventListener("focus", handleFocusInput, true);

    return () => {
      document.removeEventListener("focus", handleFocusInput, true);
    };
  }, []);

  // Добавляем эффект для определения типа устройства
  useEffect(() => {
    // Определяем тип устройства
    const isSmallDevice = window.innerHeight <= 640;
    const isTinyDevice = window.innerHeight <= 568;

    // Добавляем соответствующие классы к body
    if (isSmallDevice) {
      document.body.classList.add("small-device");
    }
    if (isTinyDevice) {
      document.body.classList.add("tiny-device");
    }

    // Функция для обновления классов при изменении размера окна
    const handleResize = () => {
      const isSmallDevice = window.innerHeight <= 640;
      const isTinyDevice = window.innerHeight <= 568;

      document.body.classList.toggle("small-device", isSmallDevice);
      document.body.classList.toggle("tiny-device", isTinyDevice);
    };

    // Добавляем обработчик
    window.addEventListener("resize", handleResize);

    return () => {
      // Удаляем классы и обработчик при размонтировании
      document.body.classList.remove("small-device", "tiny-device");
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Функция для просмотра изображения в модальном окне
  const openImageModal = (imageSrc, imageAlt) => {
    setCurrentImage(imageSrc);
    setCurrentImageAlt(imageAlt);
    setShowImageModal(true);

    // Блокируем прокрутку на время открытия модального окна
    document.body.style.overflow = "hidden";
  };

  // Функция для закрытия модального окна
  const closeImageModal = () => {
    setShowImageModal(false);
    setCurrentImage("");
    setCurrentImageAlt("");

    // Возвращаем прокрутку страницы
    document.body.style.overflow = "auto";
  };

  // Обработчики фокуса для селектов
  const handleCitySelectFocus = () => {
    setCitySelectFocused(true);
  };

  const handleCitySelectBlur = () => {
    setCitySelectFocused(false);
  };

  const handleCafeSelectFocus = () => {
    setCafeSelectFocused(true);
  };

  const handleCafeSelectBlur = () => {
    setCafeSelectFocused(false);
  };

  const handleAddressSelectFocus = () => {
    setAddressSelectFocused(true);
  };

  const handleAddressSelectBlur = () => {
    setAddressSelectFocused(false);
  };

  return (
    <>
      <div className="form-container">
        <form
          onSubmit={handleSubmit}
          className="order-form mobile-optimized"
          autoComplete="off"
          autoCorrect="off"
          spellCheck="false"
          autoCapitalize="off"
        >
          <div className="form-group">
            <div className="text-center text-2xl font-bold p-[10px] border border-gray-300 rounded-md">
              Форма приема заявок
            </div>
            <div
              className={`select-container ${selectedCity ? "has-value" : ""} ${
                citySelectFocused ? "focused" : ""
              }`}
            >
              <Select
                ref={citySelectRef}
                value={selectedCity}
                onChange={handleCityChange}
                options={cityOptions}
                styles={customStyles}
                placeholder=""
                isSearchable={false}
                required
                inputProps={{
                  autoComplete: "off",
                  autoCorrect: "off",
                  spellCheck: "false",
                  autoCapitalize: "off",
                  role: "presentation",
                  "aria-autocomplete": "none",
                  "data-lpignore": "true",
                  readonly: "readonly",
                  inputmode: "none",
                  "aria-hidden": "true",
                  tabIndex: "-1",
                }}
                onFocus={() => {
                  disableAutofill(citySelectRef);
                  handleCitySelectFocus();
                }}
                onBlur={handleCitySelectBlur}
                onMenuOpen={() => {
                  disableAutofill(citySelectRef);
                  handleCitySelectFocus();
                }}
                onMenuClose={handleCitySelectBlur}
                onTouchStart={(e) => {
                  if (citySelectRef.current?.select?.inputRef) {
                    preventKeyboard({
                      target: citySelectRef.current.select.inputRef,
                      type: "touchstart",
                      preventDefault: () => {},
                      stopPropagation: () => {},
                    });
                  }
                }}
                menuPortalTarget={getMenuPortalTarget()}
                closeMenuOnSelect={true}
                hideSelectedOptions={true}
                isClearable={true}
                backspaceRemovesValue={true}
                noOptionsMessage={() => "Нет доступных городов"}
                classNamePrefix="react-select"
                blurInputOnSelect={true}
                menuPosition="fixed"
                menuPlacement="auto"
                menuShouldBlockScroll={true}
              />
              <label
                className={`select-label ${
                  selectedCity || citySelectFocused ? "active" : ""
                }`}
              >
                Выберите город
              </label>
            </div>
            {isCustomCity && (
              <div className="floating-label-container">
                <input
                  type="text"
                  value={customCity}
                  onChange={handleCustomCityChange}
                  placeholder=" "
                  autoComplete="off"
                  autoCorrect="off"
                  spellCheck="false"
                  autoCapitalize="off"
                  onFocus={handleCityFocus}
                  onBlur={handleCityBlur}
                />
                <label className={cityFocused || customCity ? "active" : ""}>
                  Введите название города
                </label>
                {customCityError && (
                  <div className="input-error">{customCityError}</div>
                )}
              </div>
            )}
          </div>

          {(selectedCity && !isCustomCity) || isCustomCity ? (
            <div className="form-group">
              {isCustomCity ? (
                <div className="floating-label-container">
                  <input
                    type="text"
                    value={customCafe}
                    onChange={handleCustomCafeChange}
                    placeholder=" "
                    autoComplete="off"
                    autoCorrect="off"
                    spellCheck="false"
                    autoCapitalize="off"
                    onFocus={handleCafeFocus}
                    onBlur={handleCafeBlur}
                  />
                  <label className={cafeFocused || customCafe ? "active" : ""}>
                    Введите название кафе
                  </label>
                  {customCafeError && (
                    <div className="input-error">{customCafeError}</div>
                  )}
                </div>
              ) : (
                <div
                  className={`select-container ${
                    selectedCafe ? "has-value" : ""
                  } ${cafeSelectFocused ? "focused" : ""}`}
                >
                  <Select
                    ref={cafeSelectRef}
                    value={selectedCafe}
                    onChange={handleCafeChange}
                    options={cafeOptions}
                    styles={customStyles}
                    placeholder=""
                    isSearchable={false}
                    required
                    inputProps={{
                      autoComplete: "off",
                      autoCorrect: "off",
                      spellCheck: "false",
                      autoCapitalize: "off",
                      role: "presentation",
                      "aria-autocomplete": "none",
                      "data-lpignore": "true",
                      readonly: "readonly",
                      inputmode: "none",
                      "aria-hidden": "true",
                      tabIndex: "-1",
                    }}
                    onFocus={() => {
                      disableAutofill(cafeSelectRef);
                      handleCafeSelectFocus();
                    }}
                    onBlur={handleCafeSelectBlur}
                    onMenuOpen={() => {
                      disableAutofill(cafeSelectRef);
                      handleCafeSelectFocus();
                    }}
                    onMenuClose={handleCafeSelectBlur}
                    onTouchStart={(e) => {
                      if (cafeSelectRef.current?.select?.inputRef) {
                        preventKeyboard({
                          target: cafeSelectRef.current.select.inputRef,
                          type: "touchstart",
                          preventDefault: () => {},
                          stopPropagation: () => {},
                        });
                      }
                    }}
                    menuPortalTarget={getMenuPortalTarget()}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={true}
                    isClearable={true}
                    backspaceRemovesValue={true}
                    noOptionsMessage={() => "Нет доступных кафе"}
                    classNamePrefix="react-select"
                    blurInputOnSelect={true}
                    menuPosition="fixed"
                    menuPlacement="auto"
                  />
                  <label
                    className={`select-label ${
                      selectedCafe || cafeSelectFocused ? "active" : ""
                    }`}
                  >
                    Выберите кафе
                  </label>
                </div>
              )}
              {isCustomCafe && !isCustomCity && (
                <div className="floating-label-container">
                  <input
                    type="text"
                    value={customCafe}
                    onChange={handleCustomCafeChange}
                    placeholder=" "
                    autoComplete="off"
                    autoCorrect="off"
                    spellCheck="false"
                    autoCapitalize="off"
                    onFocus={handleCafeFocus}
                    onBlur={handleCafeBlur}
                  />
                  <label className={cafeFocused || customCafe ? "active" : ""}>
                    Введите название кафе
                  </label>
                  {customCafeError && (
                    <div className="input-error">{customCafeError}</div>
                  )}
                </div>
              )}
            </div>
          ) : null}

          {selectedCafe && !isCustomCafe && !isCustomCity && (
            <div className="form-group">
              <div
                className={`select-container ${
                  selectedAddress ? "has-value" : ""
                } ${addressSelectFocused ? "focused" : ""}`}
              >
                <Select
                  ref={addressSelectRef}
                  value={selectedAddress}
                  onChange={handleAddressChange}
                  options={addressOptions}
                  styles={customStyles}
                  placeholder=""
                  isSearchable={false}
                  required
                  inputProps={{
                    autoComplete: "off",
                    autoCorrect: "off",
                    spellCheck: "false",
                    autoCapitalize: "off",
                    role: "presentation",
                    "aria-autocomplete": "none",
                    "data-lpignore": "true",
                    readonly: "readonly",
                    inputmode: "none",
                    "aria-hidden": "true",
                    tabIndex: "-1",
                  }}
                  onFocus={() => {
                    disableAutofill(addressSelectRef);
                    handleAddressSelectFocus();
                  }}
                  onBlur={handleAddressSelectBlur}
                  onMenuOpen={() => {
                    disableAutofill(addressSelectRef);
                    handleAddressSelectFocus();
                  }}
                  onMenuClose={handleAddressSelectBlur}
                  onTouchStart={(e) => {
                    if (addressSelectRef.current?.select?.inputRef) {
                      preventKeyboard({
                        target: addressSelectRef.current.select.inputRef,
                        type: "touchstart",
                        preventDefault: () => {},
                        stopPropagation: () => {},
                      });
                    }
                  }}
                  menuPortalTarget={getMenuPortalTarget()}
                  closeMenuOnSelect={true}
                  hideSelectedOptions={true}
                  isClearable={true}
                  backspaceRemovesValue={true}
                  noOptionsMessage={() => "Нет доступных адресов"}
                  classNamePrefix="react-select"
                  blurInputOnSelect={true}
                  menuPosition="fixed"
                  menuPlacement="auto"
                />
                <label
                  className={`select-label ${
                    selectedAddress || addressSelectFocused ? "active" : ""
                  }`}
                >
                  Выберите адрес
                </label>
              </div>
            </div>
          )}

          {/* Поле для ввода адреса вручную */}
          {(isCustomAddress || isCustomCafe || isCustomCity) && (
            <div className="form-group">
              <div className="floating-label-container">
                <input
                  type="text"
                  value={customAddress}
                  onChange={handleCustomAddressChange}
                  placeholder=" "
                  autoComplete="off"
                  autoCorrect="off"
                  spellCheck="false"
                  autoCapitalize="off"
                  onFocus={handleAddressFocus}
                  onBlur={handleAddressBlur}
                />
                <label
                  className={addressFocused || customAddress ? "active" : ""}
                >
                  Введите адрес кафе
                </label>
                {customAddressError && (
                  <div className="input-error">{customAddressError}</div>
                )}
              </div>
            </div>
          )}

          <div className="form-group">
            <div
              className={`custom-phone-input-container with-floating-label ${
                phoneFocused || phone !== "7" ? "focused" : ""
              } ${phone !== "7" ? "filled" : ""}`}
            >
              <div className="phone-prefix">+7</div>
              <input
                type="tel"
                className="custom-phone-input"
                value={formatPhoneNumber(
                  phone.startsWith("7") ? phone.substring(1) : phone
                )}
                onChange={handleCustomPhoneChange}
                placeholder=" "
                autoComplete="off"
                autoCorrect="off"
                spellCheck="false"
                autoCapitalize="off"
                required
                onFocus={handlePhoneFocus}
                onBlur={handlePhoneBlur}
              />
              <label className="phone-label">
                Введите номер тел с whatsapp
              </label>
            </div>
            {phoneError && <div className="phone-error">{phoneError}</div>}
            {phoneInfo && <div className="phone-info">{phoneInfo}</div>}

            {/* Добавляем поле для комментария к телефону с плавающим лейблом */}
            <div className="floating-label-container">
              <input
                type="text"
                value={phoneComment}
                onChange={handlePhoneCommentChange}
                placeholder=" "
                autoComplete="off"
                autoCorrect="off"
                spellCheck="false"
                autoCapitalize="off"
                onFocus={handleCommentFocus}
                onBlur={handleCommentBlur}
              />
              <label className={commentFocused || phoneComment ? "active" : ""}>
                Режим работы (необязательно)
              </label>
            </div>
          </div>

          <div className="flex">
            {/* Блок для заявки на фритюрное масло */}
            <div className="form-group additional-order-item">
              <div className="additional-order-header">
                <div
                  className="additional-order-image-container"
                  onClick={() =>
                    openImageModal("/images/oil3.png", "Фритюрное масло")
                  }
                >
                  <img
                    src="/images/oil3.png"
                    alt="Фритюрное масло"
                    className="additional-order-image"
                  />
                  <div className="image-zoom-icon">
                    <span>🔍</span>
                  </div>
                </div>
                <div className="additional-order-title">
                  <h3>Фритюрное масло</h3>
                  <p>Добавить заявку на поставку фритюрного масла</p>
                  <button
                    type="button"
                    className="info-toggle-button"
                    onClick={toggleFryoilInfo}
                  >
                    Подробнее
                    <span
                      className={`arrow-icon ${
                        showFryoilInfo ? "down" : "right"
                      }`}
                    >
                      &#9654;
                    </span>
                  </button>
                </div>
                <div className="additional-order-toggle">
                  <button
                    type="button"
                    className={`toggle-button ${
                      fryoilOrderActive ? "active" : ""
                    }`}
                    onClick={handleFryoilToggle}
                  >
                    {fryoilOrderActive ? "- Отменить" : "+ Добавить"}
                  </button>
                </div>
              </div>

              {/* Блок с дополнительной информацией */}
              {showFryoilInfo && (
                <div className="additional-info-block">
                  <p>
                    Фритюрное масло поставляется в канистрах объемом 10 литров.
                    Минимальный заказ - 5 канистр. Цена от 110 руб/литр. Можем
                    предоставить бесплатный образец на пробу.
                  </p>
                </div>
              )}

              {fryoilOrderActive && (
                <div className="additional-order-details">
                  <div className="fryoil-order-row">
                    <div className="fryoil-quantity-container">
                      <div className="floating-label-container">
                        <input
                          type="tel"
                          className="quantity-input"
                          value={fryoilOrderValue}
                          onChange={handleFryoilValueChange}
                          placeholder=" "
                          autoComplete="off"
                          autoCorrect="off"
                          spellCheck="false"
                          autoCapitalize="off"
                          required={fryoilOrderActive}
                          onFocus={handleFryoilFocus}
                          onBlur={handleFryoilBlur}
                        />
                        <label
                          className={
                            fryoilFocused || fryoilOrderValue ? "active" : ""
                          }
                        >
                          Количество шт. по 10л
                        </label>
                      </div>
                    </div>
                    <div className="fryoil-total-volume">
                      <label>Общий объем</label>
                      <div className="volume-display">
                        {fryoilOrderValue ? parseInt(fryoilOrderValue) * 10 : 0}{" "}
                        литров
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {/* Блок для заявки на жироуловитель */}
            <div className="form-group additional-order-item">
              <div className="additional-order-header">
                <div
                  className="additional-order-image-container"
                  onClick={() =>
                    openImageModal("/images/grasetrap1.jpg", "Жироуловитель")
                  }
                >
                  <img
                    src="/images/grasetrap1.jpg"
                    alt="Жироуловитель"
                    className="additional-order-image"
                  />
                  <div className="image-zoom-icon">
                    <span>🔍</span>
                  </div>
                </div>
                <div className="additional-order-title">
                  <h3>Жироуловитель</h3>
                  <p>Добавить заявку на обслуживание жироуловителя</p>
                  <button
                    type="button"
                    className="info-toggle-button"
                    onClick={toggleGreaseInfo}
                  >
                    Подробнее
                    <span
                      className={`arrow-icon ${
                        showGreaseInfo ? "down" : "right"
                      }`}
                    >
                      &#9654;
                    </span>
                  </button>
                </div>
                <div className="additional-order-toggle">
                  <button
                    type="button"
                    className={`toggle-button ${
                      greaseOrderActive ? "active" : ""
                    }`}
                    onClick={handleGreaseToggle}
                  >
                    {greaseOrderActive ? "- Отменить" : "+ Добавить"}
                  </button>
                </div>
              </div>

              {/* Блок с дополнительной информацией */}
              {showGreaseInfo && (
                <div className="additional-info-block">
                  <p>
                    Обслуживание включает в себя замену грязного жироуловителя
                    на чистый. Цена от 900руб.
                  </p>
                </div>
              )}
            </div>
          </div>

          <button
            type="submit"
            className="submit-button"
            disabled={
              (isCustomCity && !customCity.trim()) ||
              (isCustomCafe && !customCafe.trim()) ||
              (isCustomAddress && !customAddress.trim()) ||
              (!isCustomAddress &&
                !isCustomCafe &&
                !isCustomCity &&
                !selectedAddress) ||
              !phone ||
              phone.replace(/\D/g, "").length < 11 ||
              (fryoilOrderActive && !fryoilOrderValue)
            }
          >
            Оформить заявку
          </button>
        </form>
      </div>

      {/* Модальное окно для просмотра увеличенного изображения */}
      {showImageModal && (
        <div className="image-modal-overlay" onClick={closeImageModal}>
          <div
            className="image-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <button className="image-modal-close" onClick={closeImageModal}>
              ×
            </button>
            <img
              src={currentImage}
              alt={currentImageAlt}
              className="image-modal-img"
            />
            <div className="image-modal-caption">{currentImageAlt}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default OrderForm;
