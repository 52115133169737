import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";

// Отключаем Service Worker
if ("serviceWorker" in navigator) {
  navigator.serviceWorker.ready.then((registration) => {
    registration.unregister();
  });
}

// Создаем корневой элемент для приложения
const root = ReactDOM.createRoot(document.getElementById("root"));

// Создаем портал для меню, если его еще нет
if (!document.getElementById("portal-root")) {
  const portalRoot = document.createElement("div");
  portalRoot.id = "portal-root";
  document.body.appendChild(portalRoot);
}

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
