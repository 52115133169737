import { api } from "./api";

// Отправка кода верификации на WhatsApp
export const sendVerificationCode = async (formData) => {
  try {
    const response = await api.post("/api/verification/send-code", formData);
    return response.data;
  } catch (error) {
    console.error("Ошибка при отправке кода верификации:", error);
    const errorObj = new Error(error.message);
    errorObj.status = error.response?.status;
    errorObj.data = error.response?.data;
    throw errorObj;
  }
};

// Проверка кода верификации и завершение заказа
export const verifyCode = async (phone, code) => {
  try {
    const response = await api.post("/api/verification/verify", {
      phone,
      code,
    });
    return response.data;
  } catch (error) {
    console.error("Ошибка при проверке кода верификации:", error);
    const errorObj = new Error(error.message);
    errorObj.status = error.response?.status;
    errorObj.data = error.response?.data;
    throw errorObj;
  }
};
